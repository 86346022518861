import React from "react"
import View from "./View";

import RoomTile from "./RoomTile";
import { isAllowed } from "../tools";

import { useAuthValue } from "./Auth/AuthContext";
import * as Styles from '../styles/layout.module.scss'

const Profile = ({ data }) => {
  const { currentUser } = useAuthValue();
  const rooms = data?.filter(node => {
    return isAllowed( node.data, currentUser )
    })

  return (
    <View >
      <div className={Styles.grid}>
        
      {rooms.length > 0 ? rooms.sort(((a, b) => a.data.StartDate===null
                                                      ?3:
                                                      ( a.data.StartDate < b.data.StartDate
                                                            ?1
                                                            :-1) )

).map(room => (
        <div className={room.data.StartDate <= new Date().toISOString().split('T')[0]
                       && room.data.EndDate >= new Date().toISOString().split('T')[0]
                      ?"current":(room.data.EndDate <= new Date().toISOString().split('T')[0]?"past":"future")}>
        
         <RoomTile data={room} />
         </div>
      )):(<div>
        <h4>Ihnen sind bislang keine Übungsräume zugewiesen</h4>
        <p>Das kann folgende Ursachen haben:</p>
        <ul>
          <li>Sie haben keine Kurse bei uns gebucht. Schade, aber dann bekommen Sie natürlich auch nichts zu sehen. </li>
          <li>Die Live Online Schulung hat noch nicht begonnen. Normalerweise weisen wir Teilnehmer am ersten Tag kurz vor Beginn der Veranstaltung dem Kurs zu.</li>
          <li>Sie haben sich für einen E-Learning Kurs eingeschrieben. Die Zuweisung muss von Hand erfolgen, darum kann es bis zu 24 h dauern. </li>
        </ul>
        <p>Falls Sie der Meinung sind, dass Sie schon etwas sehen sollten, dann melden Sie sich bitte per E-Mail an training@brandeis.de  </p>
      </div>)}
      </div>
    </View>

  )
}

export default Profile