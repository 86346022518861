import React from "react"
import { Link } from "@reach/router";

import * as Styles from '../styles/layout.module.scss'

const RoomTile = ({ data }) => {
  const room = data.data;
  return (

    <div className={Styles.roomTile}>
      <Link to={`/app/room/${room.Key.toLowerCase()}`}>
        <div className={Styles.col}>
          <div className={Styles.tileTextBox}>
            <h2>{room.Name}</h2>
            <h3>{room.StartDate
              ? (<div>{room.StartDateDisplay} bis {room.EndDateDisplay}</div>)
              : null} {room.Customer ? (<div> für {room.Customer}</div>) : null}</h3>
            <p>{room.Description}</p>
          </div>
          <div className={Styles.tileImageBox}>
            <img className={Styles.tileImage} src={room.logo_url} alt="Logo" />
          </div>
        </div>
        <div className={Styles.likeLink}>Zum Schulungsraum</div>
      </Link>
      {room.MeetingLink
        ? (<a href={room.MeetingLink}
          target="_blank"
          className={Styles.underlined}
          rel="noopener">Online Meeting</a>)
        : null}
    </div>
  )
}

export default RoomTile