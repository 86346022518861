import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { auth } from "../components/Firebase/Firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification
} from "firebase/auth";
import { useAuthValue } from "../components/Auth/AuthContext";
import * as Styles from '../styles/index.module.scss'
import '../i18n';

import Layout from "../components/Layout";
import View from "../components/View";
import Profile from "../components/Profile";

const Index = ({ data }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { currentUser } = useAuthValue();

  data.allMdx.nodes.forEach(trainingRoom => {
    trainingRoom.data.StartDateDisplay = new Date(trainingRoom.data.StartDate).toLocaleDateString();
    trainingRoom.data.EndDateDisplay = new Date(trainingRoom.data.EndDate).toLocaleDateString();
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Layout  >
      {currentUser ? (

        <View title={`Willkommen ${currentUser.email}`} >
          {currentUser.emailVerified ? (
            <div>
              <a>Du bist am Lernportal angemeldet und kannst die folgenden Übungsräume besuchen:</a>
              <Profile data={data.allMdx.nodes} />
            </div>
          ) : (
            <div className={Styles.instructions}>
              <h4>Du hast Deine E-Mailadresse noch nicht bestätigt</h4>
              <p>Aus Sicherheitsgründen können nur angemeldete Benutzer mit bestätigter E-Mailadresse auf unser Lernportal zugreifen. Bitte folgen Sie den folgenden</p>
              <h4>Schritte zum bestätigen der E-Mailadresse</h4>
              <ul>
                <li> Melde Dich wieder von der Seite ab:
                  <a href="/"
                    onClick={event => {
                      event.preventDefault()
                      signOut(auth)
                    }}
                  > Logout {currentUser.email}
                  </a>
                </li>
                <li> Bitte prüfe Dein E-Mail Postfach nach einer E-Mail von "Brandeis Consulting" und bestätige die Adresse.  </li>
                <li>(Falls Du keine E-Mail bekommen hast, kannst Du noch einmal eine anfordern:
                  <a href="/"
                    onClick={event => {
                      event.preventDefault()
                      sendEmailVerification(currentUser)
                    }}
                  > Bestätigungsemail anfordern für  {currentUser.email}
                  </a>
                </li>
                <li> Melde Dich wieder auf dieser Seite an. </li>
              </ul></div>)}
        </View>
      ) :
        <View title="Lernportal von Brandeis Consulting">
          <div className={Styles.instructions}>

            <p>Sie sind nicht angemeldet, darum können Sie auf dieser Seite nicht viel sehen. Angemeldete Teilnehmer der Live Online Schulungen und der E-Learning Kurse sehen hier die Liste Ihrer Schulungsräume und Veranstaltungen. Vor dem Kursbeginn bzw. nach Ihrer Anmeldung für einen E-Learning Kurs wird Ihre E-Mailadresse dem Kurs zugewiesen. Damit Sie darauf zugreiffen können müssen Sie sich registrieren und dann anmelden. </p>

            <h4>Ablauf für neue Teilnehmer</h4>
            <ul>
              <li>Folgen Sie diesem <Link to="/register"> Link zum registrieren</Link> und registrieren Sie sich auf dieser Seite. Verwenden Sie dabei Ihre geschäftliche E-Mailadresse.  </li>
              <li>Bitte prüfen Sie Ihr E-Mail Postfach nach einer E-Mail von "Brandeis Consulting" und bestätigen Sie die Adresse.  </li>
              <li>Melden Sie sich unten im Login Bereich an.</li>
            </ul>

            <h4>Login</h4>

            <div className="login">
              <div className="login__container">
                {error && <div className="auth__error">{error}</div>}
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    name="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                  />
                  <input
                    type="password"
                    name="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                  <button tabIndex="0">Login</button>
                </form>

              </div>

              <h4>Weitere Aktionen</h4>
              <ul>
                <li>
                  <Link to="/reset">Kennwort zurücksetzen</Link>
                </li>
                <li><Link to="/register">Auf der Website registrieren</Link>
                </li>
              </ul>
            </div>

          </div>
        </View>}
    </Layout>
  );
};

export default Index;


export const query = graphql`
query RoomAssignment {
  allMdx(filter: {frontmatter: {type: {eq: "trainingRoom"}}}) {
    nodes {
      data:frontmatter {
        Key
        StartDate
        EndDate
        EndDateSort: StartDate
        StartDateSort: StartDate
        MeetingLink
        Customer
        Name
        logo_url
        Description 
        Participants {
          Email
          Firstname
          Lastname
        }
        Trainers{
          Email
          Firstname
          Lastname
        }
      }
    }
  }
}
`